// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-admin-payment-link-eur-js": () => import("./../src/admin/payment-link-eur.js" /* webpackChunkName: "component---src-admin-payment-link-eur-js" */),
  "component---src-admin-payment-link-js": () => import("./../src/admin/payment-link.js" /* webpackChunkName: "component---src-admin-payment-link-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-cooking-index-js": () => import("./../src/pages/cooking/index.js" /* webpackChunkName: "component---src-pages-cooking-index-js" */),
  "component---src-pages-courses-components-courses-list-js": () => import("./../src/pages/courses/components/CoursesList.js" /* webpackChunkName: "component---src-pages-courses-components-courses-list-js" */),
  "component---src-pages-courses-cooking-jsx": () => import("./../src/pages/courses/Cooking.jsx" /* webpackChunkName: "component---src-pages-courses-cooking-jsx" */),
  "component---src-pages-courses-index-js": () => import("./../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-instructors-index-js": () => import("./../src/pages/instructors/index.js" /* webpackChunkName: "component---src-pages-instructors-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-new-js": () => import("./../src/templates/contact-new.js" /* webpackChunkName: "component---src-templates-contact-new-js" */),
  "component---src-templates-course-js": () => import("./../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-instructor-js": () => import("./../src/templates/instructor.js" /* webpackChunkName: "component---src-templates-instructor-js" */),
  "component---src-templates-slow-living-page-js": () => import("./../src/templates/slow-living-page.js" /* webpackChunkName: "component---src-templates-slow-living-page-js" */),
  "component---src-templates-your-home-away-page-js": () => import("./../src/templates/your-home-away-page.js" /* webpackChunkName: "component---src-templates-your-home-away-page-js" */)
}

