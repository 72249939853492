module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-admin/gatsby-browser.js'),
      options: {"plugins":[],"adminPath":"/opt/build/repo/src/admin","adminUri":"admin","loginUri":"/admin/","excludeUri":["/admin/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Follow Your Senses in Tuscany","short_name":"Follow Tuscany","icon":"src/img/manifest-logo.png","description":"Follow Your Senses in Tuscany Architects.","start_url":"/","background_color":"white","theme_color":"red","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
